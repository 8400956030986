<template>
  <b-container fluid>
    <b-row class="mb-4" v-if="rows">
        <b-col>
          <div>
            <b-tabs content-class="mt-3">
              <b-tab title="Topics" active>
                <div v-for="(item, index) in rows" :key="'activityTopic' + index">
                  {{item.updateDate}}
                  - {{item.user}}
                  - <router-link :to="{ name: 'NewsItem', params: { id: item.newsId }}" >{{item.newsTitle}}</router-link>
                  -> <router-link :to="{ name: 'Topic', params: { id: item.topicId }}" >{{item.topicName}}</router-link>
                </div>
              </b-tab>
              <b-tab title="Tags">
                <div v-for="(item, index) in rowsTags" :key="'activityTag' + index">
                  {{item.updateDate}}
                  - {{item.user}}
                  - <router-link :to="{ name: 'NewsItem', params: { id: item.newsId }}" >{{item.newsTitle}}</router-link>
                  -> <router-link :to="{ name: 'NewsTag', params: { id: item.tagId }}" >{{item.tagName}}</router-link>
                </div>
              </b-tab>
              <b-tab title="Events">
                <div v-for="(item, index) in events" :key="'activityEvent' + index">
                  {{item.created_at}}
                  - {{item.createdBy}}
                  - {{item.name}}
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
    </b-row>
    <b-row v-show="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'

export default {
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Recent activity"
    if (!ac.can(this.user.acgroups).readAny('rricpanel').granted) {
      this.$router.push({ name: 'Start' })
    }
    this.$stat.log({ page: 'admin recent activity', action: 'open page' })
    this.loadData()
  },
  data () {
    return {
      events: [],
      filter: '',
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      loadingNews: false,
      rows: [],
      rowsTags: []
    }
  },
  methods: {
    loadData: async function () {
      this.$logger.debug('loadData')
      this.loadingNews = true
      try {
        let apiName = 'cosmos'
        let path = `/recentactivity/page`
        let response = await this.$Amplify.API.get(apiName, path)
        let rows = []
        rows = response.topics
        for (let i = 0, len = rows.length; i < len; i++) {
          rows[i].user = rows[i].username
          rows[i].updateDate = moment(rows[i].created_at).format('YYYY-MM-DD HH:mm')
          rows[i].newsId = rows[i].publication.id
          rows[i].newsTitle = rows[i].publication.name
          rows[i].newsBody = rows[i].publication.body
          rows[i].newsLink = rows[i].publication.link
          rows[i].topicName = rows[i].regtopic.name
          rows[i].topicId = rows[i].regtopic.id
        }
        this.rows = rows
        let tags = response.tags
        for (let i = 0, len = rows.length; i < len; i++) {
          tags[i].user = tags[i].username
          tags[i].updateDate = moment(tags[i].created_at).format('YYYY-MM-DD HH:mm')
          tags[i].newsId = tags[i].publication.id
          tags[i].newsTitle = tags[i].publication.name
          tags[i].newsBody = tags[i].publication.body
          tags[i].newsLink = tags[i].publication.link
          tags[i].tagName = tags[i].tag.name
          tags[i].tagId = tags[i].tag.id
        }
        this.rowsTags = tags
        let events = response.events
        for (let i = 0, len = events.length; i < len; i++) {
          events[i].created_at = moment(events[i].created_at).format('YYYY-MM-DD HH:mm')
        }
        this.events = events
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('error' + e)
      }
    }
  }
}
</script>

<style>
</style>
